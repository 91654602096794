import { Flex, Link, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";

interface AnchorProgressProps {
  sectionIds: string[];
}

const AnchorProgress: React.FC<AnchorProgressProps> = ({ sectionIds }) => {
  const [currentSection, setCurrentSection] = useState<string | null>(null);

  useEffect(() => {
    const handleScroll = () => {
      for (const section of sectionIds) {
        const element = document.getElementById(section);
        if (element) {
          const rect = element.getBoundingClientRect();
          if (
            rect.top <= window.innerHeight / 2 &&
            rect.bottom >= window.innerHeight / 2
          ) {
            setCurrentSection(section);
            break;
          }
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [sectionIds]);

  return (
    <Flex direction="column" mx={4}>
      {sectionIds &&
        sectionIds.map((item, index) => (
          <Link href={`/illustration#${item}`} key={index}>
            <Text
              fontWeight={200}
              textAlign="end"
              fontSize="lg"
              textColor={currentSection === item ? "yellow.500" : "white"}
            >
              {item}
            </Text>
          </Link>
        ))}
    </Flex>
  );
};

export default AnchorProgress;
