import { useCallback, useEffect, useState } from "react";
import { Work } from "../model/work";
import { fetchAndActivate, getValue } from "firebase/remote-config";
import { remoteConfig } from "../utils/firebase";
import { Illustration } from "../model/illustration";

const mockData = [
  {
    title: "Graphic design",
    imageUrl:
      "https://firebasestorage.googleapis.com/v0/b/helloiamjoy-4375e.appspot.com/o/home%2Fmenu%2Fgraphic_design.png?alt=media",
    link: "https://baebearrb.wixsite.com/helloiamjoy/graphic-design",
  },
  {
    title: "Illustration",
    imageUrl:
      "https://firebasestorage.googleapis.com/v0/b/helloiamjoy-4375e.appspot.com/o/home%2Fmenu%2Fillustration.jpg?alt=media",
    link: "/illustration",
  },
  {
    title: "Kids Illustration",
    imageUrl:
      "https://firebasestorage.googleapis.com/v0/b/helloiamjoy-4375e.appspot.com/o/home%2Fmenu%2Fkids_illustration.jpg?alt=media",
    link: "/illustration#Kid%20Illustration",
  },
  {
    title: "Event Photography",
    imageUrl:
      "https://firebasestorage.googleapis.com/v0/b/helloiamjoy-4375e.appspot.com/o/home%2Fmenu%2Fevent_photography.jpg?alt=media",
    link: "https://baebearrb.wixsite.com/helloiamjoy/event-photography",
  },
  {
    title: "Art Illustration",
    imageUrl:
      "https://firebasestorage.googleapis.com/v0/b/helloiamjoy-4375e.appspot.com/o/home%2Fmenu%2Fart_illustration.jpg?alt=media",
    link: "https://baebearrb.wixsite.com/helloiamjoy/art-installation",
  },
  {
    title: "3d Production",
    imageUrl:
      "https://firebasestorage.googleapis.com/v0/b/helloiamjoy-4375e.appspot.com/o/home%2Fmenu%2F3d_production.jpg?alt=media",
    link: "https://baebearrb.wixsite.com/helloiamjoy/3d-production",
  },
];

export const useFetchWork = (): { works: Work[] | null; fetch: () => void } => {
  const [works, setWorks] = useState<Work[] | null>(null);
  const fetch = useCallback(async () => {
    setWorks(mockData);
  }, []);

  useEffect(() => {
    fetch();
  }, [fetch]);

  return { works, fetch };
};

export const useFetchIllustration = (): {
  illustrations: Illustration[] | null;
  fetch: () => void;
} => {
  const [illustrations, setIllustrations] = useState<Illustration[] | null>(null);
  const fetch = useCallback(async () => {
    // const data = getValue(remoteConfig, "illustration");
    await fetchAndActivate(remoteConfig)
    const data = getValue(remoteConfig, "illustration").asString();

    setIllustrations(JSON.parse(data) as Illustration[])
    }, []);
    
  useEffect(() => {
    fetch();
  }, [fetch]);

  return { illustrations, fetch };
};
