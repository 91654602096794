import {
  AspectRatio,
  Box,
  Card,
  ChakraProps,
  Flex,
  Heading,
  Image,
  Link,
  Text,
} from "@chakra-ui/react";
import React from "react";
import "./WorkTile.css";

interface WorkTileProps extends ChakraProps {
  title: string;
  imageUrl: string;
  link?: string;
}

const WorkTile: React.FC<WorkTileProps> = (props) => {
  return (
    <Link href={props.link} className="work-tile">
      <AspectRatio
        boxSize="100%"
        minW={{ base: "300px", md: "400px" }}
        maxW={{ base: "300px", md: "400px" }}
        ratio={3 / 2}
      >
        <Card
          background="background.primary"
          borderRadius={16}
          borderWidth={1}
          borderColor="blackAlpha.100"
          shadow={0}
          position="relative"
        >
          <Image
            objectFit="cover"
            w="100%"
            h="100%"
            src={props.imageUrl}
            position="absolute"
          />
          <Flex
            position="absolute"
            direction="column"
            alignItems="center"
            justifyContent="center"
            p={4}
            w="100%"
            h="100%"
          >
            <Box background="gray.700" className="work-tile-title">
              <Text
                fontSize="sm"
                textColor="white"
                noOfLines={4}
                px={8}
                py={4}
                textAlign="center"
              >
                {props.title}
              </Text>
            </Box>
          </Flex>
        </Card>
      </AspectRatio>
    </Link>
  );
};

export default WorkTile;
