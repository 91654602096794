import {
  Box,
  Container,
  Flex,
  Heading,
  Text,
  Image,
  Link,
} from "@chakra-ui/react";
import Toolbar from "../../components/toolbar/Toolbar";
import AnchorProgress from "./anchor-progress";
import { useFetchIllustration } from "../../hooks/useFetchWork";
import { useEffect } from "react";

const Gallery: React.FC = () => {
  const { illustrations } = useFetchIllustration();

  useEffect(() => {
    if (illustrations) {
      const hash = window.location.hash.slice(1);
      if (hash) {
        setTimeout(() => {
          const element = document.getElementById(decodeURIComponent(hash));
          console.log(element);
          if (element) {
            element.scrollIntoView({ behavior: "smooth" });
          }
        }, 2000);
      }
    }
  }, [illustrations]);

  return (
    <Box bg="black" height="100%">
      <Toolbar />
      <Container maxW="1024px">
        <Heading
          size={{ base: "md", md: "lg" }}
          mt={8}
          textColor="white"
          textAlign="center"
        >
          What I do.
        </Heading>
        <Text textColor="white" mt={4} fontWeight={200} textAlign="center">
          What I do. Here are samples of my digital painting portfolio. I have
          proudly worked as a freelance artist for the past 7 years, primarily
          specializing in the publishing industry. Additionally, I have
          established my Facebook page, under the name '_baebearr_', for 3 years
          to showcase my artwork and keep connecting with my client.
        </Text>
        <Text
          textColor="whiteAlpha.700"
          fontSize="sm"
          mt={6}
          fontWeight={200}
          textAlign="center"
        >
          Check my Facebook here :{" "}
          <Link href="https://facebook.com/BaebearrArts" isExternal>
            facebook.com/BaebearrArts
          </Link>
        </Text>

        <Flex direction="row" mt={12}>
          <Flex direction="column" bg="black">
            {(illustrations?.length ?? 0) > 0 &&
              illustrations?.map((illustration, index) => {
                if (illustration.imageUrl) {
                  return (
                    <Image
                      id={illustration.anchor}
                      maxW={{ base: "100%" }}
                      src={illustration.imageUrl}
                      alt=""
                      key={index}
                    />
                  );
                }
              })}
          </Flex>
          <Flex
            alignItems="center"
            position="fixed"
            top={0}
            right={0}
            bottom={0}
          >
            <AnchorProgress
              sectionIds={
                illustrations
                  ?.filter((item) => item.anchor)
                  .map((item) => item.anchor || "") ?? []
              }
            />
          </Flex>
        </Flex>
      </Container>
    </Box>
  );
};

export default Gallery;
