import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getRemoteConfig } from "firebase/remote-config";

const firebaseConfig = {
  apiKey: "AIzaSyAFT71qw-mWl8M2UovE9EwpOLXSQtRaiaU",
  authDomain: "helloiamjoy-4375e.firebaseapp.com",
  projectId: "helloiamjoy-4375e",
  storageBucket: "helloiamjoy-4375e.appspot.com",
  messagingSenderId: "512157398661",
  appId: "1:512157398661:web:0bb522041a711641ea5bec",
  measurementId: "G-QZJGJ4TN70"
};

const app = initializeApp(firebaseConfig);

export const analytics = getAnalytics(app);

const config = getRemoteConfig(app);
config.settings.minimumFetchIntervalMillis = 3600000;

export const remoteConfig = config
