import { Flex, Heading, Link } from "@chakra-ui/react";

const Toolbar: React.FC = () => {
  return (
    <Flex direction="row" p={{ base: 6, md: 8 }} bg="white">
      <Link href="/" _hover={{ textDecoration: "none" }}>
        <Heading
          as="h1"
          fontWeight={400}
          size={{ base: "lg", md: "xl" }}
          style={{ letterSpacing: ".75em" }}
        >
          APICHAYA JOY
        </Heading>
      </Link>
    </Flex>
  );
};

export default Toolbar;
