import {
  Box,
  Container,
  Flex,
  Heading,
  HStack,
  Icon,
  Link,
  Text
} from "@chakra-ui/react";
import { logEvent } from "firebase/analytics";
import React, { useEffect } from "react";
import {
  AiOutlineFacebook,
  AiOutlineInstagram,
  AiOutlineLinkedin,
  AiOutlineMail,
  AiOutlinePhone,
} from "react-icons/ai";
import Toolbar from "../../components/toolbar/Toolbar";
import WorkTile from "../../components/worktile/WorkTile";
import { useFetchWork } from "../../hooks/useFetchWork";
import { analytics } from "../../utils/firebase";

const Home: React.FC = () => {
  const { works } = useFetchWork();

  useEffect(() => {
    logEvent(analytics, "home_screen_viewed");
  }, []);

  return (
    <Box bg="white">
      <Toolbar />
      <Container maxW="1024px">
        <Flex direction="column" alignItems="center">
          <Heading
            as="h2"
            size={{ base: "md", md: "lg" }}
            mt={12}
            style={{ letterSpacing: "0.2em" }}
            noOfLines={1}
          >
            About me.
          </Heading>
          <Text
            fontSize={{ base: "lg", md: "xl" }}
            mt={8}
            px={4}
            textAlign="center"
            color="gray.600"
            fontWeight={200}
          >
            Hello, I am Joy. I have been working as a freelancer for over 7
            years. My passion lies in creating unique and visually appealing
            designs. I specialize in graphic design and digital illustration. I
            also possess a wide range of design projects. You can explore all of
            my design on my website. Let's work together to create something
            great.
          </Text>
          <Heading
            as="h4"
            size={{ base: "sm" }}
            mt={12}
            style={{ letterSpacing: "0.2em" }}
            noOfLines={1}
          >
            Contact me.
          </Heading>
          <Link href="mailto:apichaya10344@gmail.com" isExternal>
            <Flex direction="row" alignItems="center" mt={4}>
              <Icon as={AiOutlineMail} boxSize="1.5em" />
              <Text
                fontWeight={200}
                ml={4}
                alignContent="center"
                alignItems="center"
              >
                apichaya10344@gmail.com
              </Text>
            </Flex>
          </Link>
          <Link href="tel:+4477-87729413" isExternal>
            <Flex direction="row" alignItems="center" mt={4}>
              <Icon as={AiOutlinePhone} boxSize="1.5em" />
              <Text
                fontWeight={200}
                ml={4}
                alignContent="center"
                alignItems="center"
              >
                (+44) 7787729413
              </Text>
            </Flex>
          </Link>
          <HStack spacing={6} direction="row" mt={8}>
            <Link href="https://www.instagram.com/_baebearr_" isExternal>
              <Icon
                as={AiOutlineInstagram}
                boxSize="2.5em"
                _hover={{ color: "gray.400" }}
              />
            </Link>
            <Link href="https://www.facebook.com/BaebearrArts" isExternal>
              <Icon
                as={AiOutlineFacebook}
                boxSize="2.5em"
                _hover={{ color: "gray.400" }}
              />
            </Link>
            <Link
              href="https://th.linkedin.com/in/apichaya-wajanavisit-527903199"
              isExternal
            >
              <Icon
                as={AiOutlineLinkedin}
                boxSize="2.5em"
                _hover={{ color: "gray.400" }}
              />
            </Link>
          </HStack>
          <Box width="177px" height="5px" mt={12} background="black" />
        </Flex>
      </Container>
      <Container maxW={{ base: "100%" }} my={8} px={{ base: 0 }}>
        <HStack
          spacing={4}
          alignItems="start"
          overflowX="auto"
          overflowY="auto"
          py={8}
          px={{ base: 4, md: 8 }}
        >
          {works &&
            works.map((work, index) => (
              <WorkTile
                key={index}
                title={work.title}
                imageUrl={work.imageUrl}
                link={work.link}
              />
            ))}
        </HStack>
      </Container>
    </Box>
  );
};

export default Home;
